import { Outlet, redirect, useRouteLoaderData } from '@remix-run/react';
import { useEffect } from 'react';
import { $routeId } from 'remix-routes';

import { EnumsPageName } from '@lp-lib/api-service-client/public';

import { useMyOrganizationFeatureChecker } from '../components/Organization/hooks/organization';
import { PortalLink } from '../components/PortalContext';
import { FeatureChecker } from '../components/Product/FeatureChecker';
import { PublicHomeProvider } from '../components/PublicHome/PublicHomeProvider';
import {
  REDIRECT_CUSTOM_TARGET_MAP,
  useUserRedirect,
} from '../hooks/useRedirection';
import { apiService } from '../services/api-service';
import { OrganizerRoleUtils } from '../types';

export async function clientLoader() {
  let user;
  try {
    const response = await apiService.auth.verify();
    user = response.data.user;
  } catch (error) {}

  if (user) {
    const fc = FeatureChecker.GetFeatureChecker(user);
    const isAdminOrOrgOwner = OrganizerRoleUtils.isOwnerOrAdmin(user.organizer);
    const learningPath = isAdminOrOrgOwner ? '/learning/admin' : '/learning';
    const onlyLearningEnabled =
      fc.hasUnlimitedTrainingAccess() && !fc.hasAnyOndGamePlayAccess();
    if (onlyLearningEnabled) {
      throw redirect(learningPath);
    }
  }

  const pageName = EnumsPageName.PageNamePublicHome;
  const page = (await apiService.page.get(pageName)).data.page;
  return {
    pageName,
    page,
  };
}

export function usePublicHomeData() {
  const data = useRouteLoaderData<typeof clientLoader>(
    $routeId('routes/_shell-client.home._shell')
  );
  if (!data) throw new Error('No data');
  return data;
}

export function shouldRevalidate(): boolean {
  return false;
}

function Bootstrap() {
  const redirect = useUserRedirect();
  const featureChecker = useMyOrganizationFeatureChecker();

  useEffect(() => {
    redirect([
      { kind: 'organizer', target: REDIRECT_CUSTOM_TARGET_MAP.noop },
      { kind: 'venueActivated', target: PortalLink.HostGamePack },
      {
        kind: 'guest',
        target: REDIRECT_CUSTOM_TARGET_MAP.noop,
        clearToken: true,
      },
      { kind: 'final', target: REDIRECT_CUSTOM_TARGET_MAP.noop },
    ]);
  }, [redirect, featureChecker]);

  return null;
}

export function Component(): JSX.Element {
  return (
    <PublicHomeProvider>
      <Outlet />
      <Bootstrap />
    </PublicHomeProvider>
  );
}
